import { useState } from "react";
import { LOGIN_PAGE, REGISTRATION_PAGE } from "../../const/constNames";
import Login from "./Login";
import Registration from "./Registration";
import PublicMessage from "./PublicMessage";


export default function PublicRouter () {

    const [page,setPage] = useState(LOGIN_PAGE)
    const [message,setMessage] = useState("")


    function getChild () {
        switch (true) {
            case message.length>0 :
                return <PublicMessage
                message={message}
                />

            case page === REGISTRATION_PAGE :
                return <Registration 
                selectPage={setPage}
                setMessage={setMessage}
                />
    
            default:
                return <Login 
                selectPage={setPage}
                message={message}
                />
        }
    }

    return getChild()


}