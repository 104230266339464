import './App.css';
import AuthRequired from './containers/authentication/AuthRequired';
import "./icons/icons.css"

function App() {

  return (
   <>
    <AuthRequired/>
   </>
  );
}

export default App;
