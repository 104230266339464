
export default function Writing () {




    return (       
            <div 
            className='penIcon room-icon' 
            />
    )
}