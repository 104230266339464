import Title from "./Title"


export default function PublicMessage ({message}) {
    
     return (
    <div className="publicMessageContainer">
        <Title/>
        <p>{message}</p>
    </div>)


}