import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { VERIFICATION_QUERY } from '../../const/constNames';
import { apiURL, sendVerificationTokenURL } from '../../const/constsURL';

export default function VerificationHandler () {

    const [verificationStatus, setVerificationStatus] = useState('');
    


    useEffect(() => {
        // Function to parse query parameters
        const getQueryParam = (param) => {
          const urlParams = new URLSearchParams(window.location.search);
          return urlParams.get(param);
        };
    
        const token = getQueryParam(VERIFICATION_QUERY);
        if (token) {
          const axiosVerification = axios.create(
            {
                baseURL: apiURL,
                headers: {
                    "Content-Type": "application/json",
                    'Authorization':`${token}`
                        },
                        withCredentials: false
            }
        )
          // If there's a token, send it to the backend for verification
          // axiosVerification.headers['Authorization'] = `${token}`
          axiosVerification.get(sendVerificationTokenURL)
            .then(response => {
              setVerificationStatus('success');
              // Optionally, clear the query params from the URL
              window.history.replaceState(null, null, window.location.pathname);
            })
            .catch(error => {
              setVerificationStatus('failure');
            });
        }
      }, []);

    return <>
     {verificationStatus === 'success' && <p>Verification successful!</p>}
      {verificationStatus === 'failure' && <p>Verification failed.</p>}
    </>
}